<template>
  <b-card>
    <div class="d-flex align-items-center justify-content-between table-header">
      <h2 class="text-primary mb-1">
        {{ typeof $route.meta.pageTitle === 'function' ? $route.meta.pageTitle() : $route.meta.pageTitle }}
      </h2>
      <div class="d-flex align-items-center">
        <b-button class="ml-1" variant="dark" @click.prevent="$router.push({ name: 'paket' })">
          <feather-icon icon="ListIcon" />
          <span class="d-none d-md-inline">Back To List</span>
        </b-button>
      </div>
    </div>

    <vue-form-json-schema v-model="modelData" :schema="schema" :ui-schema="uiSchema" />

    <b-row>
      <b-col md="12">
        <validation-provider #default="{ errors }" name="harga paket" rules="required">
          <b-form-group label="Harga Paket (Rp)" label-for="hargapaket">
            <cleave id="hargapaket" v-model="modelData.hargapaket" class="form-control" :raw="false"
              :options="options.number" placeholder="Masukkan harga paket" />
          </b-form-group>
          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </b-col>

      <b-col md="12">
        <validation-provider #default="{ errors }" name="satuan" rules="required">
          <b-form-group label="Satuan" label-for="satuan" :state="errors.length > 0 ? false : null">
            <v-select id="satuan" v-model="modelData.satuan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="satuanoptionfield" label="text" placeholder="Pilih" />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

    <b-button class="d-flex align-items-center mt-50" variant="success" @click.prevent="handleSubmit">
      <feather-icon class="mr-50" icon="SaveIcon" />
      Save
    </b-button>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import uiSchema from './schema/FormRequestUiSchema.json'
import schema from './schema/FormRequestSchema.json'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    ToastificationContent,
    Cleave,
  },
  data() {
    return {
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        }
      },
      modelData: {
        nama: '',
        hargapaket: '',
        satuan: '',
        kodeerp: '',
      },
      idPaket: 0,
      satuanoptionfield: ['Pax'],
      schema,
      uiSchema,
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.getDetailData()
    }
  },
  methods: {
    handleSubmit() {
      if (this.$route.params.id) {
        this.handleEdit()
      } else {
        this.handleCreate()
      }
    },

    handleCreate() {
      if (!this.validateForm()) {
        return
      }

      const data = {
        nama: this.modelData.nama,
        hargapaket: parseInt(this.modelData.hargapaket.replace(/,/g, '')),
        satuan: this.modelData.satuan,
        kodeerp: this.modelData.kodeerp,
      }
      this.$http.post('/api/paket/create', data).then(res => {
        if (!res.data.status) {
          this.$toast({
            component: ToastificationContent, position: 'top-right',
            props: {
              title: 'Notification',
              icon: 'SlashIcon',
              variant: 'danger',
              text: res.data.message,
            },
          });
          return
        }

        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'CheckIcon',
            variant: 'success',
            text: '👋Berhasil menyimpan data',
          },
        });

        setTimeout(() => {
          this.$router.push('/paket')
        }, 1000)
      }).catch(e => {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: 'Gagal menyimpan data' + e,
          },
        });
      })
    },

    handleEdit() {
      if (!this.validateForm()) {
        return
      }
      const data = {
        kodepaket: this.data.idPaket,
        nama: this.modelData.nama,
        hargapaket: parseInt(this.modelData.hargapaket.replace(/,/g, '')),
        satuan: this.modelData.satuan,
        kodeerp: this.modelData.kodeerp,
      }
      this.$http.post('/api/paket/edit', data).then(res => {
        if (!res.data.status) {
          this.$toast({
            component: ToastificationContent, position: 'top-right',
            props: {
              title: 'Notification',
              icon: 'SlashIcon',
              variant: 'danger',
              text: res.data.message,
            },
          });
          return
        }

        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'CheckIcon',
            variant: 'success',
            text: '👋Berhasil menyimpan data',
          },
        });

        setTimeout(() => {
          this.$router.push('/paket')
        }, 1000)
      }).catch(e => {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: 'Gagal menyimpan data' + e,
          },
        });
      })
    },

    getDetailData() {
      this.$http.get(`/api/paket/detail/${this.$route.params.id}`).then(res => {
        const response = res.data.data
        this.data = { idPaket: response.kodepaket }
        this.modelData = {
          nama: response.nama,
          hargapaket: response.hargapaket,
          satuan: response.satuan,
          kodeerp: response.kodeerp,
        }
      }).catch(e => {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: 'Gagal mendapatkan data' + e,
          },
        });
      })
    },

    validateForm() {
      if (this.modelData.nama === '' || this.modelData.nama === null) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Silakan mengisi nama makanan',
          },
        });
        return false;
      }

      if (this.modelData.hargapaket === '' || this.modelData.hargapaket === null) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Silakan mengisi harga paket',
          },
        });
        return false;
      }
      if (!this.modelData.hargapaket || !/^[0-9]/.test(this.modelData.hargapaket)) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Harga paket hanya mengandung angka',
          },
        });
        return false
      }

      if (this.modelData.kodeerp === '' || this.modelData.kodeerp === null) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Silakan mengisi kode erp',
          },
        });
        return false;
      }

      if (this.modelData.satuan === '' || this.modelData.satuan === null) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Silakan mengisi satuan',
          },
        });
        return false;
      }

      return true
    }
  }
}
</script>
